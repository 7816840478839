import React, { createContext, useContext, useState, useCallback, ReactNode } from "react";
import dynamic from 'next/dynamic';

type IframeModalParams = {
  iframeUrl: string
};

type TModalParams = IframeModalParams | null;

type ModalContextType = {
  setModalName: (modalName: TModalKind) => void;
  modalParams: TModalParams,
  setModalParams: (modalParams: TModalParams) => void;
};

const ModalContext = createContext<ModalContextType | undefined>(undefined);

const WelcomeBannerModal = dynamic(
  () => import('@/components/modals/WelcomeBannerModal/WelcomeBannerModal'),
  {
      ssr: false,
  }
);

const SpbIframeModal = dynamic(
  () => import('@/components/modals/SpbIframeModal/SpbIframeModal'),
  {
      ssr: false,
  }
);

export type TModalKind =
  | 'clear'
  | 'welcome'
  | 'sbp_iframe';

const MODALS: { [key in TModalKind]: JSX.Element | null } = {
    clear: null,
    welcome: <WelcomeBannerModal />,
    sbp_iframe: <SpbIframeModal />,
};

export const ModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [modalName, setModalName] = useState<TModalKind>('clear');
    const [modalParams, setModalParams] = useState<TModalParams>(null);

    const handleModalType = useCallback(
      (modalName: TModalKind) => {
          setModalName(modalName);
      },
      []
    );

    return (
        <ModalContext.Provider value={{ setModalName: handleModalType, modalParams, setModalParams }}>
            {children}
            {MODALS[modalName]}
        </ModalContext.Provider>
    );
};

export const useModal = () => {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error("useModal must be used within a ModalProvider");
    }
    return context;
};
